@import 'styles/fonts';

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html{
  scroll-behavior: smooth;
}

.temp{
  position: fixed;
  bottom: 1rem;
  left: 1rem;
  display: flex;
  z-index: 5;
  button{
    border-radius: 1rem;
    border: 0;
    margin: 0 .5rem 0;
    width: 120px;
    padding: .4rem;
  }
}

.loader-mini{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 50px;
  height: 50px;
  border: 5px solid var(--primary);
  border-width: 5px 0 0 5px;
  border-radius: 50%;
  animation: rot 1s linear infinite;
}

@keyframes rot {
  0%{
    transform: translate(-50%, -50%) rotate(0);
  }
  100%{
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.loader{
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(255,255,255,1);
  width: 100vw;
  height: 100vh;
  opacity: 0;
  pointer-events: none;
  transition: opacity 1s ease;
  z-index: 100;
  &.active{
    opacity: 1;
    pointer-events: initial;
  }
  .center{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    img{
      width: 50px;
      height: 100px;
      &:first-child{
        animation: up 2.5s ease infinite;
      }
      &:last-child{
        animation: up 4s ease reverse infinite;
      }
    }
  }
}

@keyframes up {
  0%{
    transform: translateY(0);
    opacity: 0;
  }
  20%{
    opacity: 1;
  }
  60%{
    opacity: 1;
  }
  100%{
    opacity: 0;
    transform: translateY(100%);
  }
}

.small-message{
  position: fixed;
  top: 5%;
  left: 50%;
  transform: translate(-50%, -200%);
  background-color: white;
  border: 3px solid var(--secundary);
  padding: 1rem 2rem;
  border-radius: .5rem;
  box-shadow: 0 0 5px rgba(0,0,0,.2);
  z-index: 100;
  transition: transform .9s cubic-bezier(.76,.19,.28,.98);
  &.active{
    transform: translate(-50%,0%);
  }
}

body, button, a{
  font-family:'ITC Avant Garde Gothic Std Medium';
  font-weight: normal;
}
button{
  cursor: pointer;
  &[disabled]{
    background-color: var(--light-gray) !important;
  }
}
h1, h2, h3, h4, h5{
  font-family: 'ITC Avant Garde Gothic Std Bold';
  font-weight: 100;
}
.main{
  --gray-text: #666666;
  --gray: #304766;
  --list-bg: #E6E6E6;
  --light-gray: #B3B3B3;
  --dark-list-bg: #CCCCCC;
  --text-primary: white;
  --text-secundary: #304766;
  &.eb{
    --primary: #005f32;
    --primary-second: #186e45;
    --secundary: #186e45;
    --ternary: #005f32;
    --ternary-second: #f7c852;
    button, a {
      &.ternary-second {
        background-color: var(--ternary-second);
        color: black;
      }
    }
    footer {
      background: linear-gradient(to bottom right, #000, var(--primary));
    }
  }
  &.envia{
    --primary: #052b89;
    --primary-second: #052b89;
    --secundary: #009733;
    --ternary: #052b89;
    --ternary-second: #009733;
    .text-secondary {
      color: var(--primary) !important;
    }
  }
  &.sendero{
    --primary: #059a7d;
    --primary-second: #059a7d;
    --secundary: #52b9a5;
    --ternary: #059a7d;
    --ternary-second: #52b9a5;
  }
  .detail-2{
    position: absolute;
    right: 0;
    width: 100px;
    @media (max-width: 730px){
      opacity: .1;
    }
  }
}

.detail-1{
  width: 100px;
  position: absolute;
  bottom: 0;
  left: 0;
  @media (max-width: 730px){
    opacity: .1;
  }
}

.two-col{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  @media (max-width: 730px){
    grid-template-columns: 1fr;
  }
}
.two-col-5-11 {
  display: grid;
  grid-template-columns: 5fr 11fr;
  @media (max-width: 730px){
    grid-template-columns: 1fr;
  }
}
.two-col-2-2 {
  display: grid;
  grid-template-columns: 2fr 2fr;
  margin-left: 25px;
  @media (max-width: 730px){
    margin-left: 0px;
    grid-template-columns: 1fr;
  }
}
.two-col-3-1 {
  display: grid;
  grid-template-columns: 3fr 1fr;
  @media (max-width: 730px){
    grid-template-columns: 1fr;
  }
}
.two-col-1-3 {
  display: grid;
  grid-template-columns: 1fr 3fr;
  @media (max-width: 730px){
    grid-template-columns: 1fr;
  }
}
.two-col-1-4 {
  display: grid;
  grid-template-columns: 1fr 4fr;
  @media (max-width: 730px){
    grid-template-columns: 1fr;
  }
}
.tree-middle3-col {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  @media (max-width: 730px){
    grid-template-columns: 1fr;
  }
}

button, a{
  border-radius: .3rem;
  &.primary, &.secundary, &.ternary, &.ternary-second{
    display: block;
    text-decoration: none;
    cursor: pointer;
    padding: .7rem;
    font-size: 1rem;
    margin: 0 auto;
    border: 2px solid white;
  }
  &.primary{
    background-color: var(--ternary);
    color: white;
    width: 200px;
    text-align: center;
  }
  &.secundary{
    border-color: var(--primary);
    color: var(--primary);
    background-color: transparent;
  }
  &.ternary{
    background-color: var(--secundary);
    color: white;
  }
  &.ternary-second {
    &[disabled] {
      color: white;
    }
    background-color: var(--ternary-second);
    color: white;
  }
}

.wrapper{
  max-width: 1200px;
  position: relative;
  margin: 0 auto;
  p{
    font-size: 1rem;
    color: var(--gray-text);
  }
}

header{
  width: 100%;
  padding: 1rem;
  margin-bottom: 1rem;
  background-color: white;
  // border-bottom: 1px solid var(--light-gray);
  .wrapper{
    display: grid;
    align-items: center;
    grid-template-columns: 130px 1fr;
  }
  .menu-btn{
    display: none;
  }
  .menu{
    display: flex;
    justify-content: flex-end;
    p{
      margin-right: 1rem;
    }
    a{
      text-decoration: none;
      color: var(--gray-text);
    }
  }
  @media (max-width: 730px) {
    .wrapper{
      grid-template-columns: 40px 1fr 40px;
      >a{
        height: 60px;
        grid-column: 2;
        img{
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
    .menu-btn, a{
      z-index: 11;
    }
    .menu-btn{
      display: block;
      width: 20px;
      height: 50px;
      background-color: transparent;
      border: 0;
      img{
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .menu{
      top: calc(61px + 2rem);
      left: 0;
      display: block;
      position: fixed;
      width: 100vw;
      height: calc(100vh - 61px - 2rem);
      background-color: white;
      z-index: 10;
      transition: transform .4s ease;
      transform: translateX(100%);
      &.open{
        transform: translateX(0);
      }
      p,a {
        margin: 0;
        display: block;
        padding: 2rem 1rem;
        text-align: center;
        // border-bottom: 1px solid var(--light-gray);
      }
    }
  }
}

footer {
  position: relative;
  overflow: hidden;
  margin-top: 104px;
  padding: 73px 1rem 3rem 1rem;
  background-color: var(--primary);
  .wrapper{
    display: flex;
    flex-direction: row;
    position: relative;
    justify-content: space-between;
    &.social-only{
      justify-content: center;
      .phones{
        display: none;
      }
    }
  }
  .pleca{
    position:absolute;
    top:-430%;    
    width: 100%;
    display: inline-flex;
    img{
      width: 300px;     
      margin-left: auto;
      margin-right: auto;
      display: block;       
    }
  }
  .links{
    // position: absolute;
    // top: -200%;
    // left: 0;
    // width: 100%;
    a, p{
      display: inline-block;
      color: white;
      font-size: 1rem;
      border-radius: 0;
      margin: 0 .2rem;
      padding: 0 .5rem;
      border-left: 1px solid white;
      &:first-child{
        border-left: initial;
      }
    }
  }
  .social, .phones{
    display: flex;
    position: relative;
    >div{
      align-items: center;
      display: flex;
      margin: 0 .5rem;
      &:first-child{
        border-right: 1px solid white;
        padding-right: calc(1rem + 1px);
      }
    }
    a{
      font-size: 1rem;
      color: white;
      text-decoration: none;
    }
    img{
      width: 40px;
      height: 40px;
      margin: 0 .5rem;
    }    
  }
  @media (max-width: 630px) {
    padding: 70px 1rem 1rem 1rem;
    .wrapper{
      display: block;
      &::before{
        top: initial;
      }
      .pleca{        
        top:-95px;        
        img {
          width: 150px;
          height: 150px;
        }
      }
      .links{
        top: 0px;
        text-align: center;
      }
      .social, .phones{
        top: 40px;
        align-items: center;
        justify-content: center;
        padding: 2rem 1rem;
      }
    }
  }
}

.text-secondary {
  color: var(--secundary) !important;
}

.link{
  color: var(--secundary) !important;
  // position: relative;
  width: fit-content;
  cursor: pointer;
  &::before{
    content: '';
    width: 120%;
    height: 1px;
    // position: absolute;
    bottom: -.3rem;
    left: 0;
    background-color: var(--secundary);
  }
}

section{
  min-height: calc(100vh - 314px);
  overflow: hidden;
  h1{
    text-align: center;
    background-color: var(--primary);
    color: var(--text-primary);
    padding: .5rem 1rem;
    font-size: 2rem;
    b{
      display: block;
    }
  }
}

.select-wrapper, .input-wrapper{
  margin: .5rem 0;
  position: relative;
  img{
    width: 20px;
    height: 20px;
    position: absolute;
    left: .5rem;
    top: .3rem;
    z-index: 1;
  }
  input, label, select{
    display: block;
    width: 100%;
    position: relative;
    &[type=date]{
      color: var(--gray-text);
      font-family:'ITC Avant Garde Gothic Std Medium';
    }
  }
  label{
    color: var(--text-secundary);
    margin-bottom: .5rem;
    font-weight: bold;
  }
  input, select{
    padding: .4rem 2.4rem;
    &.error{
      border-color: var(--ternary);
    }
    
  }
  button{
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 0;
    background-color: var(--secundary);
    text-align: center;
    position: absolute;
    top: .2rem;
    right: .4rem;
    svg{
      width: 50%;
      height: 50%;
      vertical-align: middle;
      path{
        fill: white;
      }
    }
  }
}

.dato{
  margin-bottom: 1rem;
  p{
    b{
      color: var(--text-secundary);
    }
  }
}
.registro-disclaimer{
  display: grid;
  grid-template-columns: 50px 1fr;
  grid-gap: 1rem;
  align-items: center;
  width: fit-content;
  margin: 0 auto;
  p{
    line-height: 1.4rem;
  }
  b{
    background-color: var(--secundary);
    color: white;
    padding: .2rem;
    border-radius: .5rem;
  }
}
.modal-wrapper{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  opacity: 0;
  background-color: rgba(0,0,0,.6);
  transition: opacity .5s ease;
  z-index: 15;
  .modal{
    width: 90%;
    max-width: 600px;
    max-height: 90%;
    overflow: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%) scale(0);
    background-color: white;
    transition: transform .5s ease;
    padding: .5rem .5rem 1rem ;
    .head{
      border-bottom: 1px solid var(--light-gray);
      padding: .5rem 1rem;
      display: flex;
      justify-content: space-between;
      h4{
        font-size: 1.3rem;
        color: var(--primary);
      }
      h5{
        font-size: 1.2rem;
        color: var(--primary);
      }
      img{
        width: 20px;
        height: 20px;
        align-self: flex-end;
        cursor: pointer;
      }
    }
    .content{
      padding: 1rem 1rem 0 1rem;
      overflow-y: scroll;      
      max-height: 500px;
      .top{
        display: grid;
        align-items: center;
        grid-gap: 1rem;
        grid-template-columns: 1fr 50px 110px;
        img{
          width: 90%;
          position: relative;
          display: block;
          margin: 0 auto;
          &:nth-child(2){
            width: 50px;
          }
        }
      }
      .action{
        position: relative;
        &.flex{
          display: flex;
          button{
            width: 100%;
            margin: 0 .5rem;
          }
        }
      }
      .check-wrapper{
        margin: 3rem 3rem 3rem auto;
        position: relative;
        width: fit-content;
        input{
          margin-right: .5rem;
        }
      }
      .radio-wrapper{
        >label{
         color: var(--primary); 
         font-size: 1.3rem;
         font-family: 'ITC Avant Garde Gothic Std Bold';
        }
        .radios{
          display: flex;
          padding: 1rem .5rem;
          align-items: center;
          .radio{
            margin-right: 1rem;
            label{
              margin-right: .5rem;
            }
          }
        }
      }
    }
  }
  &.active{
    opacity: 1;
    pointer-events: initial;
    .modal{
      transform: translate(-50%,-50%) scale(1);
    }
  }
}

.seal{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 200px;
}

.highlight{
  display: grid;
  grid-template-columns: 40px 1fr;
  align-items: center;
  max-width: 80%;
  margin: 2rem auto 0;
  p{
    margin: 0 !important;
    &:first-child{
      font-size: 3rem;
      color: var(--primary);
    }
  }
}

.modal-wrapper{
  .modal{
    .two-col{
      grid-gap: 1rem;
      @media (max-width: 730px){
        grid-gap: 0;
      }
    }
    &.ejemplo{
      .content{
        margin-top: 0;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1rem;
        padding-right: 13rem;
        .seal{
          left: initial;
          right: 0;
        }
        .example{
          img{
            width: 100%;
            height: 300px;
            object-fit: contain;
          }
          p{
            text-align: center;
            color: var(--primary);
          }
        }
      }
    }
    &.steps{
      .content{
        padding: 1rem;
        margin-top: 0;
        .step{
          .step-head{
            display: grid;
            grid-template-columns: 100px 1fr;
            align-items: center;
            padding: 1rem 0;
            h3{
              font-size: 1.6rem;
              color: var(--primary);
            }
          }
          p{
            color: var(--primary);
          }
          img{
            width: 100%;
            margin: 1rem 0;
          }
        }
        .important{
          display: flex;
          align-items: center;
          background-color: var(--primary);
          color: white;
          padding: 1rem;
          h3{
            margin-right: 1rem;
          }
          p{
            font-size: .7rem;
          }
        }
      }
    }
    &.help, &.error{
      max-width: 380px;
      overflow: hidden;
      &::before, &::after{
        position: absolute;
        width: 50px;
      }
      &::before{
        top: 0;
        left: 0;
      }
      &::after{
        bottom: 0;
        right: 0;
      }
      h4, p{
        text-align: center;
      }
      h4{
        color: var(--primary);
        font-size: 3rem;
      }
      span{
        margin-left: auto;
      }
      p{
        color: var(--gray-text);
        b{
          color: var(--primary);
        }
      }
      svg{
        width: 140px;
        margin: 1rem auto;
        display: block;
        path{
          fill: var(--primary);
        }
      }
    }
  }
}

.form-wrapper, .registro{
  .input-wrapper{
    margin: 0;
  }
}


.home{
  position: relative;
  .welcome-footer{
    position: relative;
    min-height: 100px;
  }
  .detail-2{
    top: 20%;
  }
  .detail-home{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100px;
  }
  h1{
    position: relative;
    &::before, &::after{
      object-fit: contain;
      width: 53px;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
    &::after{
      left: initial;
      right: 0;
      transform: rotate(-90deg);
    }
  }
  .text{
    margin: 3rem;
    position: relative;
    text-align: center;
    h3{
      color: var(--gray-text);
      font-size: 1.2rem;
      font-family: 'ITC Avant Garde Gothic Std Medium';
      b{
        font-weight: 500;
        color: var(--primary);
        font-family: 'ITC Avant Garde Gothic Std Bold';
      }
    }
    h4{
      color: var(--text-secundary);
      font-size: 1.4rem;
      margin-top: 2rem;
    }
    .seal{
      left: 10%;
      top: 100%;
    }
    p{
      max-width: 600px;
      margin: 0 auto;
      &.steps{
        margin-top: 1rem;
        font-family: 'ITC Avant Garde Gothic Std Bold';
        color: var(--primary);
      }
    }
  }
  .action{
    position: relative;
    text-align: center;
  }
  .pleca{
    margin-top: 2rem;
    text-align: center;
    padding: 1rem;
    position: relative;
    border-bottom: 1px solid var(--gray);
    img{
      max-width: 500px;
      margin: 0 auto;
      position: relative;
    }
  }

  .slider{
    --sliders: 7;
    width: 100%;
    max-width: 900px;
    overflow: hidden;
    border-bottom: 1px solid var(--gray);
    margin: 0 auto;
    position: relative;
    padding-bottom: 1rem;
    background-color: white;
    nav{
      position: absolute;
      bottom: .1rem;
      left: 50%;
      transform: translateX(-50%);
      z-index: 2;
      button{
        background-color: transparent;
        border: 0;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin: 0 .2rem;
        border: 1px solid var(--gray-text);
        &.active{
          background-color: var(--gray-text);
        }
      }
    }
    .slider-wrapper{
      width: calc(100% * var(--sliders));
      white-space: nowrap;
      .slide{
        width: calc(100% / var(--sliders));
        display: inline-block;
        transition: transform .4s ease;
        transform: translateX(0%);
      }
    }
    img{
      width: 100%;
    }
    &.two{
      .slider-wrapper .slide{
        transform: translateX(-100%);
      }
    }
    &.three{
      .slider-wrapper .slide{
        transform: translateX(-200%);
      }
    }
    &.four{
      .slider-wrapper .slide{
        transform: translateX(-300%);
      }
    }
    &.five{
      .slider-wrapper .slide{
        transform: translateX(-400%);
      }
    }
    &.six{
      .slider-wrapper .slide{
        transform: translateX(-500%);
      }
    }
    &.seven{
      .slider-wrapper .slide{
        transform: translateX(-600%);
      }
    }
  }
}

.generar-factura, .mis-facturas{
  .pleca{
    position:absolute;    
    width: 100%;
    height: fit-content;
    img{
      width: 300px;     
      margin-left: auto;
      margin-right: auto;
      display: block;       
    }
  }
  h1{
    position: relative;
    margin: 2rem 0;
    &::before, &::after{
      object-fit: contain;
      width: 42px;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
    &::after{
      left: initial;
      right: 0;
      transform: scaleX(-1);
    }
  }
  .action{
    position: relative;
    .detail-4{
      position: absolute;
      bottom: -25px;
      right: 0;
      width: 70px;
    }
  }
}

.generar-factura{
  position: relative;
  .detail-2{
    top: 30%;
  }
  .detail-1{
    bottom: 310px;
  }
  h2, h1{
    text-align: center;
    font-size: 2rem;
    font-family:'ITC Avant Garde Gothic Std Medium';
    b{
      font-family:'ITC Avant Garde Gothic Std Bold';
      font-weight: 100;
    }
  }
  
  h2{
    color: var(--primary);
  }
  .steps{
    display: flex;
    align-items: center;
    justify-content: center;
    // border-bottom: 1px solid var(--gray-text);
    padding-bottom: 2rem;
    .step{
      margin: 0 1rem;
      text-align: center;
      max-width: 100px;
      position: relative;
      svg{
        width: 70px;
        height: 70px;
        path{
          fill: var(--gray-text)
        }
      }
      p{
        position: relative;
        &::after, &::before{
          content: '';
        }
        &::after{
          position: absolute;
          top: 4px;
          width: 140px;
          height: 1px;
          left: 46%;
          z-index: 1;
          background-color: var(--light-gray);
        }
        &::before{
          z-index: 2;
          width: 9px;
          height: 9px;
          border-radius: 50%;
          display: block;
          position: relative;
          margin:  1rem auto;
          background-color: var(--gray-text);
        }
      }
      &:last-child{
        p{
          &::after{
            display: none;
          }
        }
      }
      &.active{
        svg path{
          fill: var(--primary-second);
        }
        p{
          color: var(--primary-second);
          &::before, &::after{
            background-color: var(--primary-second);
          }
        }
      }
      &.final{
        svg path{
          fill: var(--primary);
        }
        p{
          color:var(--primary);
          &::before, &::after{
            background-color: var(--primary);
          }
        }
      }      
    }
  }
  .datos{
    padding: 0 1rem;
  }
  .content, .list{
    max-width: 700px;
    margin: 2rem auto 0;
  }
  .content{
    position: relative;
    .disclaimer{
      margin: 2rem 0;
    }
    p{
      margin: 1rem 0;
    }
    .action{
      margin: 2rem 0;
    }
    .factura-type{
      margin: 3rem 0;
      display: flex;
      button{
        width: 100%;
        padding: .5rem 1rem;
        background-color: transparent;
        border: 1px solid var(--secundary);
        color: var(--secundary);
        &:first-child{
          margin-right: 1rem;
        }
        &.active{
          background-color: var(--secundary);
          color: white;
        }
      }
    }
    .form{
      padding: 0 1rem;
    }
  }

  .list{
    margin-top: 3rem;
    border-top: 1px solid var(--gray-text);
    padding: 2rem 0;
    >p{
      margin-bottom: 1rem;
    }
    .list-wrapper{
      width: 100%;
      overflow: auto;
      background-color: var(--list-bg);
      .empty{
        padding: 1rem;
      }
      table {
        border-collapse:collapse;    
      }
      tr {
          border-bottom: 1px solid var(--light-gray);
          color: var(--text-secundary);
      }
      td {
        padding: 10px 0px 10px 0px;
        text-align: center;
      }
    }
  }
}

.datos-fiscales {
  .data{
    margin: 0 auto;
    max-width: 900px;
    >p{
      position: relative;
      margin: 2rem 0;
      padding: 0 1rem;
      color: var(--secundary);
    }
  }
  .dato{
    p{
      b{
        font-weight: 100;
      }
    }
  }
  .preview-wrapper{
    position: relative;
    .change-factura{
      width: 70px;
      height: 40px;
      // z-index: 5;
      border: 0;
      background-color: var(--primary);
      border-radius: 2rem;
      img{
        width: 80%;
        height: 80%;
        vertical-align: middle;
      }
    }
  }

  @keyframes flip {
    0%{
      transform: translate(-50%,-50%) scale(1);
      opacity: 1;
    }
    50%{
      transform: translate(50%,-50%) scale(1);
      opacity: 1;
    }
    100%{
      transform: translate(-50%,-62%) scale(.9);
      opacity: .9;
    }
  }
  
  
  .preview{
    background-color: var(--list-bg);
    padding: 1rem;
    opacity: 1;
    box-shadow: 5px 0px 10px rgba(0,0,0,.3);
    transition: z-index .3s step-end, transform .5s ease, opacity .5s ease;
    transition-delay: .4s;
    >img{
      width: 300px;
      display: block;
      right: 1rem;
      top: 1rem;
    }
    
    .datos-generales{
      margin-bottom: 1rem;
      border-bottom: 1px solid var(--light-gray);
      .dato{
        p{
          text-align: right;
        }
      }
      h2, h3{
        text-align: right;
        font-family: 'ITC Avant Garde Gothic Std Bold';
        color: var(--primary);
        margin-bottom: .5rem;
      }
    }
    .datos-fiscales{
      background-color: var(--dark-list-bg);
      padding: 0.5rem 0.5rem 0rem 0.5rem;
    }
  }
  .resumen-wrapper{
    margin: 1rem 0;
    background-color: white;
    box-shadow: 0 4px 5px rgba(0,0,0,.1);
    border-radius: 1rem;
    position: relative;
    // overflow: hidden;

    .resumen-wrapper-info {
      padding: 10px 30px;
    }
    
    .resumen{
      padding: 10px 30px;
      overflow: auto;
      max-height: 200px;
      position: relative;
      table{
        td, th{
          text-align: left;
          padding: .5rem;
          max-width: min-content;
          overflow: hidden;
          border-bottom: 1px solid #ddd;
          &:not(:first-child){
            border-left: 1px solid #ddd;
          }
        }
        th{
          color: var(--gray);
          font-weight: 100;
        }
        td{
          color: var(--gray-text);
        }
      }
      .dato{
        margin: 0;
        padding: 0 1rem;
        max-width: 400px;
        >p{
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          margin-bottom: .5rem;
        }
      }
      .row{
        display: grid;
        padding: .5rem 0;
        grid-template-columns: 110px 140px 1fr 105px;
        &:not(:first-child){
          border-top: 1px solid var(--light-gray);
        }
      }
      .resumen-totales {
        padding: .5rem;
      }
      @media (max-width: 600px){
        .row{
          grid-template-columns: repeat(4, 1fr);
        }
      }
    }
    @media (max-width: 730px){
      &::after{
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 50px;
        background: rgb(255,255,255);
        background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 91%);
      }
    }
  }
  .disclaimer{
    color: var(--primary);
    margin: 3rem 0;
  }
  .actions{
    display: flex;
    padding: 0 1rem;
    button{
      width: 100%;
      &:first-child{
        margin-right: 1rem;
      }
    }
  }
}

.generar{
  .wrapper{
    max-width: 700px;
    text-align: center;
    h2{
      font-family: 'ITC Avant Garde Gothic Std Bold';
    }
    .head{
      padding: 2rem 0;
      p{
        font-size: 1.6rem;
        margin: 2rem 0;
        color: var(--primary);
      }
      .actions{
        margin: 1rem 0;
        .factura-info{
          display: none;
        }
        button, a{
          width: 80px;
          height: 80px;
          background-color: transparent;
          border: 0;
          padding: 0;
          display: inline-block;
          margin: 0 .5rem;
          img{
            width: 100%;
            height: 100%;
          }
        }
        .buttons{
          position: relative;
          button{
            position: relative;  
            &:hover{
              >.downloads-env{
                opacity: 1;
                transform: translate(-50%, 0);
                pointer-events: initial;
              }
            }
          }
          .downloads-env{
            position: absolute;
            bottom: 100%;
            border-radius: .5rem;
            background-color: white;
            border: 1px solid var(--light-gray);
            display: flex;
            left: 50%;
            transform: translate(-50%, -50%);
            padding: .5rem;
            opacity: 0;
            pointer-events: none;
            transition: transform .4s ease, opacity .4s ease;
            a, button{
              width: initial;
              height: 27px;
              background-color: var(--primary);
              color: white;
              padding: .5rem;
              border-radius: .5rem;
              text-decoration: initial;
            }
          }
          
        }
        &.multiple{
          padding: 1rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          .factura{
            border: 1px solid var(--light-gray);
            background-color: white;
            box-shadow: 0 4px 5px rgba(0,0,0,.1);
            padding: 1rem;
            border-radius: 1rem;
            display: inline-flex;
            flex-wrap: wrap;
            width: fit-content;            
            grid-template-columns: 1fr 150px;
            &:nth-child(2){
              margin: 1rem 0;
            }
            .factura-info{
              display: inline-flex;
              text-align: left;
              p{
                margin: 1rem 0 0;
              }
              img{              
                width: 275px;
                height: 75px;
                object-position: left;
              }
            }
            .buttons{
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }
    }
    .info{
      background-color: var(--list-bg);
      padding: 2rem;
      text-align: left;
      .nota{
        // border-bottom: 1px solid var(--light-gray);
        padding-bottom: 2rem;
        margin-bottom: 2rem;
      }
      .contacto{
        h2{
          text-align: left;
        }
        p{
          &:nth-child(2){
            margin: 1rem 0;
          }
        }
      }
      .link{
        margin-top: 4rem;
      }
    }
  }
}
.link-footer {
  cursor: pointer;
  &:hover {
    text-decoration: underline !important;
  }
}
.mis-facturas{
  .detail-1{
    bottom: initial;
    top: 500px;
  }
  .detail-2{
    top: 50%;
  }
  h1{
    padding: 1.7rem 0;
  }
  .wrapper{
    max-width: 700px;
    padding: 2rem 1rem;
    >p{
      color: var(--primary);
      padding: 2rem 0;
    }
    .input-wrapper{
      margin-bottom: 1rem;
    }
    .list{
      margin-top: 2rem;
      // border-bottom: 1px solid var(--light-gray);
      .row{
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        text-align: center;
        padding: 2rem 0;
        border: 1px solid var(--light-gray);
        border-width: 1px 0 0px 0;
        .dato{
          margin: 0;
        }
      }
      .buttons{
        position: relative;
        button, a{
          position: relative; 
          background-color: transparent;
          border: 0;
          margin: 0 .5rem;
          img{
            width: 40px;
            height: 40px;
          } 
          &:hover{
            >.downloads-env{
              opacity: 1;
              transform: translate(-50%, 0);
              pointer-events: initial;
            }
          }
        }
        .downloads-env{
          position: absolute;
          bottom: 100%;
          border-radius: .5rem;
          background-color: white;
          border: 1px solid var(--light-gray);
          display: flex;
          left: 50%;
          transform: translate(-50%, -50%);
          padding: .5rem;
          opacity: 0;
          pointer-events: none;
          transition: transform .4s ease, opacity .4s ease;
          a, button, p{
            width: initial;
            height: 27px;
            background-color: var(--primary);
            color: white;
            padding: .5rem;
            margin: 0 0.2rem;
            border-radius: .5rem;
            text-decoration: initial;
          }
        }
        
      }
    }
  }
}

// Tooltip
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -60px;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
.mt-3 {
  margin-top: 15px;
}

.text-align-justify {
  text-align: justify;
}